import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"our-services"} />
		<Helmet>
			<title>
				Предложения сервисного центра OMAR
			</title>
			<meta name={"description"} content={"Каждое вождение доведено до совершенства"} />
			<meta property={"og:title"} content={"Предложения сервисного центра OMAR"} />
			<meta property={"og:description"} content={"Каждое вождение доведено до совершенства"} />
			<meta property={"og:image"} content={"https://lumidionsell.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lumidionsell.com/img/3253523.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lumidionsell.com/img/3253523.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lumidionsell.com/img/3253523.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lumidionsell.com/img/3253523.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lumidionsell.com/img/3253523.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lumidionsell.com/img/3253523.png"} />
			<meta name={"msapplication-TileColor"} content={"https://lumidionsell.com/img/3253523.png"} />
		</Helmet>
		<Components.ToluqyeHeader />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Наши услуги
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Сервисный центр OMAR
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="0px 0px 30px 0px"
				grid-template-columns="repeat(2, 1fr)"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://lumidionsell.com/img/5.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Текущее обслуживание
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Поддерживайте свой автомобиль в идеальном состоянии с помощью наших услуг по регулярному техническому обслуживанию.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					margin="0px 30px 0px 30px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://lumidionsell.com/img/6.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Замена масла
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Необходима для здоровья и долговечности двигателя.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="32.5%"
					lg-width="35%"
					background="#ffdd61"
					border-radius="4px"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://lumidionsell.com/img/7.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						background="#ffdd61"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						display="flex"
						flex-direction="column"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Проверка тормозов
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Обеспечивает вашу безопасность при каждой остановке.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://lumidionsell.com/img/7.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Передовая диагностика
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Мы используем самые современные технологии для быстрого и точного выявления проблем.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://lumidionsell.com/img/9.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Диагностика двигателя
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Выявление и устранение проблем с двигателем до их обострения.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://lumidionsell.com/img/10.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Ремонт трансмиссии
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						От мелких регулировок до полного восстановления.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Это лишь краткий обзор того, что мы предлагаем в сервисном центре OMAR. Чтобы получить полное представление о том, как мы можем помочь в решении ваших конкретных автомобильных задач, мы приглашаем вас связаться с нами или посетить наш центр. Вы на собственном опыте убедитесь в том, как мы можем повысить производительность и долговечность вашего автомобиля.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				background="#ffdd61"
				color="--dark"
				type="link"
				href="/contact-us"
				text-decoration-line="initial"
			>
				Контакты
			</Button>
		</Section>
		<Components.ToluqyeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});